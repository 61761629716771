import React from "react"
import { navigate } from "gatsby"

import {
  main,
  navigation,
  error,
  paragraph,
  pillsContainer,
  bluePill,
  redPill,
} from "../sass/404.module.scss"

class NotFoundPage extends React.Component {
  static defaultProps = {
    colSize: 11,
    interval: 30,
    frequency: 0.005,
    speed: 1.6,
  }

  constructor(props) {
    super(props)
    this.draw = this.draw.bind(this)
    this.updateDimensions = this.updateDimensions.bind(this)
    this.onBlueButtonClick = this.onBlueButtonClick.bind(this)
    this.state = {
      canvas: null,
    }
  }

  componentDidMount() {
    this.setState({ canvas: this.refs.canvas }, () => {
      const columns = []
      const canvas = this.state.canvas
      const context = canvas.getContext("2d")
      const size = this.props.colSize
      const source = "0 0 1 1"
      const width = window.innerWidth
      const height = window.innerHeight
      canvas.width = width
      canvas.height = height

      const numberOfColumns = Math.floor((width / size) * 3)

      this.setState(
        { canvas, columns, context, size, source, numberOfColumns },
        () => {
          for (let i = 0; i < numberOfColumns; i++) {
            columns.push(0)
          }

          this.draw()
          const interval = setInterval(this.draw, 50 / this.props.speed)
          this.setState({ interval })

          window.addEventListener("resize", this.updateDimensions)
        }
      )
    })
  }

  draw() {
    const context = this.state.context
    const columns = this.state.columns
    const canvas = this.state.canvas
    const numberOfColumns = this.state.numberOfColumns

    context.fillStyle = "rgba(0,0,0,0.05)"
    context.fillRect(0, 0, canvas.width, canvas.width)
    context.fillStyle = "#00cc33"
    context.font = "700 13.5px Consolas,monaco,monospace"

    for (let columnIndex = 0; columnIndex < numberOfColumns; columnIndex++) {
      const index = Math.floor(Math.random() * this.state.source.length)
      const character = this.state.source[index]
      const positionX = columnIndex * this.state.size
      const positionY = columns[columnIndex] * this.state.size

      context.fillText(character, positionX, positionY)
      if (
        positionY >= canvas.height &&
        Math.random() > 1 - this.props.frequency
      ) {
        columns[columnIndex] = 0
      }
      columns[columnIndex]++
    }

    this.setState({ context, columns })
  }

  updateDimensions() {
    const canvas = this.state.canvas
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight
  }

  onBlueButtonClick() {
    window.alert(
      "Oh hell no!!! Guillaume didn't work that hard on his website so you can escape that easily! You're being redirected to the home page!"
    )
    navigate("/")
  }

  render() {
    return (
      <div className={main}>
        <canvas ref="canvas" />
        <div className={navigation}>
          <div className={error}>
            ERROR <span>404</span>
          </div>
          <p
            className={paragraph}
          >{`The page you are looking for might have been removed, had its name changed or isn't real
...or is it? Do you really want to know what's real?

You take the blue pill; the story ends, you wake up outside of gbouffard.com and believe whatever you want to believe.

You take the red pill; you stay on gbouffard.com and Guillaume shows you how deep the rabbit hole goes on his website.

Remember, all that Guillaume is offering is the truth...`}</p>
          <div className={pillsContainer}>
            <button
              aria-label="blue pill click"
              className={bluePill}
              onClick={() => this.onBlueButtonClick()}
            ></button>
            <button aria-label="red pill click" className={redPill} onClick={() => navigate("/")}></button>
          </div>
        </div>
      </div>
    )
  }
}

export default NotFoundPage
